.login-container {
    width: 750px;
    /* margin: 50px auto; */
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    /* background-color: #f9f9f9; */
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20%;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

.error {
    color: red;
    margin-bottom: 15px;
}

.gradient-background {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.gradient-background::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 2px solid white !important;
}