body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes downDownEffect {
	0% {
		transform: translateY(-15%);
	}
	100% {
		transform: translateY(0%);
	}		
}

.popup {
  animation-name: downDownEffect;
  animation-duration: 0.25s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}

.disabled {
  color: gray;         /* Gray out the text */
  cursor: not-allowed; /* Change the cursor to indicate it's disabled */
  pointer-events: none; /* Prevent mouse events */
  opacity: 0.6;        /* Make it slightly transparent */
}