.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CourseCard {
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border: 1px solid #dedede;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.CourseCard:hover {
  box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.2); /* Adds a shadow effect */
}

.ant-table-tbody > tr > td {
  border: none
}

.profileTitle {
  font-family: "Gill Sans", sans-serif;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 5px;
  font-size: 26px;
}

.UserAvatar {
  width: 32px;
  height: 32px;
  border: 1px solid gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items:center;
}

.ChatRow {
  margin-top: 10px;
}

.AIChatRow {
  font-size: 12pt;
  text-align: left;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 5px;
}

.UserChatContent {
  font-size: 12pt;
  text-align: left;
  border: 1px solid rgb(230, 230, 230);
  background-color: rgb(230, 230, 230);
  padding: 15px;
  border-radius: 15px;
  margin-right: 15px;
}

.AppHeader {
  background-color: white;
  /* box-shadow: 1px 1px 2px 0px rgb(178, 178, 178); */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  margin-bottom: 3px;
  text-align: left;
  padding-left: 8px;
  padding-right: 12px;
  border-radius: 0px 0px 3px 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.UserInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
}

.courseCardMarginLeft{
  width: 4px;
  height: 100%;
}

.courseCardMarginLeftHover{
  background-color: gray;
  border: 1px solid white;
}

.cellMove {
  animation: moveAnimation 0.3s ease-in-out;
}

@keyframes moveAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100px);
  }
}

.ant-tabs-tab {
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-top: 8px !important;
  /* font-weight: bold; */
}

.ant-table {
  border-radius: 3px !important;
}

.closeButton {
  position: relative;
  left: 46%;
  top: 13%;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0);
}

.closeButton:hover {
  background-color: rgb(198, 198, 198);
  border: 2px solid rgb(198, 198, 198);
  transition: all 0.15s ease-in;
}

.courseDetailTitle {
  display: flex;
}

.courseDetailTitle > span {
  width: 75%;
}

.courseDetailTitle > strong {
  width: 25%;
}

.lowerProgramRequirementWrapper {
  min-height: 350px;
}

.lowerProgramRequirementSmallCard {
  width: 105px;
  height: 35px;
  text-align: justify;
  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.lowerProgramRequirementSmallCardCheckBox > span > span{
  background-color: rgb(131, 200, 131) !important;
  border-color: rgb(131, 200, 131) !important;
}

.lowerProgramRequirementSmallCardCheckBox > span > input{
  cursor: default !important;
}

.lowerProgramRequirementSmallCardCheckBox {
  cursor: default !important;
}

.Pane.vertical.Pane1 {
  overflow: auto !important;
}

.Pane.vertical.Pane2 {
  overflow: auto !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5c5c5c;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.flexcenterspacedbetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-dropdown-menu-title-content > p {
  margin-top: 5px;
  margin-bottom: 5px;
}

@keyframes flash {
  0% {
    background-color: white;
  }
  33% {
    background-color: lightgray;
  }
  66% {
    background-color: white;
  }
  85% {
    background-color: lightgray;
  }
  100% {
    background-color: white;
  }
}

.flash-row {
  animation: flash 1s ease;
}

.flash-cell {
  animation: flash 1s ease;
}

.AddNewChatButton {
  margin: 4px;
  height: 40px;
  display: flex;
  cursor: pointer;
  transition: border-color 0.3s ease, 
              background 0.3s ease, 
              padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 12px;
  margin-bottom: 6px;
  align-items: center;
  padding-left: 8px;
  border-radius: 8px;
}

.AddNewChatButton:hover {
  background-color: rgba(240, 240, 240, 1);
}

.ChatNameEllipse {
  font-size: 16px;
}

.ChatNameEllipse:hover {
  color: rgba(30, 30, 30, 0.5);
}


@keyframes gradient-rotation {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.QCDropArea {
  --angle: 0deg; /* Initial angle */
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100%; /* Adjust width as needed */
  height: 72px;
  margin-bottom: 4px;
  font-size: 17px;
  color: gray;
  font-weight: bold;

  border: 2px solid transparent; /* Border for the gradient */
  border-radius: 8px;

  /* Layered background: one for padding area, one for border */
  background: 
    linear-gradient(#fff, #fff) padding-box, /* Inner background */
    linear-gradient(var(--angle), #ee7652, #e73c7e, #23a6d5, #23d5ab) border-box; /* Border gradient */

  animation: gradient-rotation 4s linear infinite;
}


.AddCourseDropArea {
  --angle: 0deg; /* Initial angle */
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-bottom: 4px;
  font-size: 17px;
  color: gray;
  font-weight: bold;

  border: 2px dashed gray; /* Border for the gradient */
  border-radius: 8px;
}

.MajorResizer {
  background: gray;
  cursor: col-resize;
  width: 3px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 2px;
  transition: all 0.4s;
}

.MajorResizer:hover{
  background: lightblue;
}

.highlighted-row{
  background-color: #bdbdbd; /* Light red background for rows with low scores */
}